import React, { useEffect, useState } from "react";
import JobService from "../../services/job.service";



const DashboardLeftTable = () => {
  const [recentJobData, setRecentJobData] = useState([])

  const getJob = () => {
    JobService.getJob().then((resp) => {
      console.log(resp.data);
      const data = resp.data.sort(
        (a, b) => new Date(b.jobs_ago) - new Date(a.jobs_ago)
      );
      setRecentJobData(data);
    });
  };


  useEffect(() => {
    getJob();
  }, []);

  return (
    <div className='bg-[#FFFFFF] px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1'>
      {/* <strong className="text-gray-700 font-medium">Recent Jobs</strong> */}
      <strong className=" font-medium">Recent Jobs</strong>
      <div className="mt-3">
        <table className="w-full text-gray-700" >
          <thead>
            <tr className="font-bold border-b border-black">
              <td>Title</td>
              <td>Company</td>
              <td>Post Date</td>
            </tr>
          </thead>
          <tbody>
            {recentJobData.map(job => (

              <tr key={job.job_id}>
                <td>{job.title}</td>
                <td>{job.company}</td>
                <td>{job.jobs_ago}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DashboardLeftTable;
