import React from 'react';
import Typed from 'react-typed';
import connection from '../../images/connection.jpg';

const Hero = () => {
  return (
    <div className='bg-[#FFFFFF] text-black pt-6'>
      <div className='max-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center'>

        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          The new way to communicate
        </h1>

        <p className='text-[#000000] md:text-4xl sm:text-3xl text-2xl font-bold p-2 md:py-6'>
          AlumniHub: The alumni website for connecting graduates.
        </p>

        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            Link Alumni:
          </p>
          <Typed
            className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-blue-400'
            strings={['Feeds', 'Events', 'Career', 'Mentorship']}
            // typeSpeed={12000000000000}
            // backSpeed={14000000000000}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>Start Connecting now</p>
        <a href='/signup'>
          <button className='bg-[#000000] w-[200px] rounded-md font-bold my-5 mx-auto py-3 text-[#FFFFFF]'>Register Now </button>
        </a>
        <img className='w-[600px] mx-auto my-4' src={connection} alt='/' />
      </div>

    </div>
  );
};

export default Hero;