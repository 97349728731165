import React, { useEffect, useState } from "react";
import authService from "../../services/auth.service";

const ProfileItem = ({ user, updateProfile, isAdmin = false ,isStaff= false}) => {
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState(user);
  const [password, setPassword] = useState("");

  const handleEdit = () => {
    setEditing(true);
  };

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleSave = () => {
    authService
      .updateUserProfile(userData)
      .then((resp) => {
        if(isAdmin || isStaff){
          updateProfile()
        }
        console.log(resp.data);
      })
      .catch((error) => {
        console.error("failed:", error);
      });

    // Perform API call or update logic here to save the edited user data
    setEditing(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "role" && !isAdmin) {
      return;
    }

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formDataa = new FormData();
      formDataa.append("file", file);
      authService
        .updateProfilePic(formDataa)
        .then((e) => updateProfile())
        .catch((e) => console.log(e));
    }
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    // Open file upload dialog
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.addEventListener("change", handleUpload);
    fileInput.click();
  };

  const ToggleSwitch = ({ name, label, defaultValue }) => {
    const [isOn, setIsOn] = useState(defaultValue);

    const toggleSwitch = (e) => {
      setUserData((prevState) => ({
        ...prevState,
        [name]: !isOn,
      }));
    };

    return (
      <div className="mb-4">
        <label
          htmlFor={`toggle-${label}`}
          className="flex items-center cursor-pointer"
        >
          <div className="relative flex items-center">
            {editing ? (
              <input
                type="checkbox"
                id={`toggle-${label}`}
                className="sr-only"
                name={name}
                checked={isOn}
                onChange={toggleSwitch}
              />
            ) : (
              <input
                type="checkbox"
                id={`toggle-${label}`}
                className="sr-only"
                name={name}
                checked={isOn}
                onChange={toggleSwitch}
                disabled
              />
            )}
            <div
              className={`w-10 h-4 rounded-full shadow-inner ${
                isOn ? "bg-blue-300" : "bg-gray-300"
              }`}
            ></div>
            <div
              className={`absolute w-6 h-6 bg-blue-500 rounded-full shadow transform transition-transform ${
                isOn ? "translate-x-6" : "translate-x-0"
              }`}
            ></div>
          </div>
          <div className="ml-3 text-gray-700">{label}</div>
        </label>
      </div>
    );
  };

  return (
    <div>
      <div className="relative mt-2 h-32 overflow-hidden rounded-xl ml-1 mr-1 bg-blue-500 bg-cover bg-center"></div>
      <div className="relative w-auto flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md mx-2 -mt-16 mb-5 lg:mx-4">
        <div className="text-3xl font-bold ml-3 py-2">
          <div
            className="profile-container"
            onContextMenu={handleRightClick}
            style={{
              position: "relative",
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              className="profile-pic"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={
                userData?.avatar
                  ? userData?.avatar
                  : require(`../../images/avatar.jpeg`)
              }
              alt="Profile Picture"
            />
          </div>
          {userData?.firstname} {userData?.lastname}
        </div>
        <div className="text-2xl font-bold ml-3 py-2 mb-5">
          {userData?.company}:{" "}
          <span className="font-medium ml-3 py-2 mb-5">
            {" "}
            {userData?.title}{" "}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 lg:col-span-1">
            {/* Personal Information */}
            <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow">
              <h2 className="text-2xl font-bold mb-4">Personal Information </h2>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Username:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="username"
                      value={userData?.username}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.username}</span>
                  )}
                </div>
              </div>
              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  First Name:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="firstname"
                      value={userData?.firstname}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.firstname}</span>
                  )}
                </div>
              </div>
              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Last Name:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="lastname"
                      value={userData?.lastname}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.lastname}</span>
                  )}
                </div>
              </div>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Email:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="email"
                      value={userData?.email}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.email}</span>
                  )}
                </div>
              </div>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Phone Number:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="phoneNum"
                      value={userData?.phoneNum}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.phoneNum}</span>
                  )}
                </div>
              </div>

              {/* Add more fields as needed */}
            </div>
          </div>{" "}
          {/* end of Personal Information */}
          <div className="col-span-2 lg:col-span-1">
            {/* Academic Information */}
            <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow">
              <h2 className="text-2xl font-bold mb-4">Academic Information </h2>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Department:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="department"
                      value={userData?.department}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.department}</span>
                  )}
                </div>
              </div>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Credential Type:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="credentialType"
                      value={userData?.credentialType}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.credentialType}</span>
                  )}
                </div>
              </div>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Program:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="program"
                      value={userData?.program}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.program}</span>
                  )}
                </div>
              </div>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Last Term Attended:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="lastTermAttended"
                      value={userData?.lastTermAttended}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.lastTermAttended}</span>
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
          {/* end of Academic Information */}
          <div className="col-span-2 lg:col-span-1">
            {/* Work Information */}
            <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow">
              <h2 className="text-2xl font-bold mb-4">Work Information </h2>

              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Company:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="company"
                      value={userData?.company}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.company}</span>
                  )}
                </div>
              </div>
              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Title:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="title"
                      value={userData?.title}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.title}</span>
                  )}
                </div>
              </div>
              <div className="flex mb-4">
                <label className="block text-gray-700 font-bold w-1/3">
                  Start Year:
                </label>
                <div className="w-2/3">
                  {editing ? (
                    <input
                      type="text"
                      name="startYear"
                      value={userData?.startYear}
                      onChange={handleChange}
                      className="border border-gray-300 rounded p-2 w-full"
                    />
                  ) : (
                    <span>{userData?.startYear}</span>
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
          {/* end of Work Information */}
          <div className="col-span-2 lg:col-span-1">
            {/* Account Settings */}
            <div className="max-w-md mx-auto p-4 border border-gray-300 rounded shadow">
              <h2 className="text-2xl font-bold mb-4">Account Settings </h2>
              <div>
                {/* Toggle switches */}

                {/* <ToggleSwitch
                  label="I would like to share my location"
                  name="isReferer"
                  defaultValue={userData.isReferer}
                /> */}

                {editing ? (
                  <div className="mb-4">
                    <label
                      className="block text-grey-darker text-sm font-bold mb-2"
                      //   htmlFor="email"
                    >
                      Password
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                      id="password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        handleChange(e);
                      }}
                      placeholder="Password"
                      required
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="mb-4">
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="role"
                  >
                    Role
                  </label>
                  {(editing && isAdmin) ? (
                    <select
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                      id="role"
                      name="role"
                      disabled={!isAdmin ? "disabled" : ""}
                      value={userData?.role}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="user">User</option>
                      <option value="staff">Staff</option>
                      <option value="admin">Admin</option>
                    </select>
                  ) : (
                    <span>{userData?.role}</span>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="status"
                  >
                    Status
                  </label>
                  {editing && isAdmin ? ( <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="role"
                    name="isActive"
                    value={userData?.isActive}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>) : (
                    <span>{(userData?.isActive)?"Active":"Inactive"}</span>
                  )}
                </div>

                <br></br>
                <ToggleSwitch
                  label="I would like to be a mentor"
                  name="isMentor"
                  defaultValue={userData?.isMentor}
                />
                {/* <ToggleSwitch
                  label="I would like to be a referer"
                  name="isReferer"
                  defaultValue={userData?.isReferer}
                /> */}
              </div>
            </div>
          </div>{" "}
          {/* end of Account Settings */}
        </div>
        <div className="mt-4 mb-4 text-center">
          {editing ? (
            <button
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
          ) : (
            <button
              onClick={handleEdit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Edit
            </button>
          )}

            {((isAdmin || isStaff ) && userData.isMentor && !userData.isApprovedMentor) && (
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-6"
                onClick={() => {
                  userData.isApprovedMentor = true;
                  handleSave();
                }}
                >Approve Mentor</button>
              )
            }

        </div>
      </div>
    </div>
  );
};

export default ProfileItem;
