import React, { useEffect, useState, Component } from 'react';
import { Navigate, Route, useNavigate } from "react-router-dom";
import background from '../../images/background.jpg'
import AuthService from "../../services/auth.service";
import FormHeader from './formHeader';
import commonHelper from '../helper/commonHelper';
import { isValidDate } from '@fullcalendar/core';

class ChangePassword extends Component {

    state = {
        newPassword: "",
        confirmPassword: "",
        error: "",
        isValidPassword: false
    }
    constructor(props) {
        super(props);
    }


    handleSubmit = (e) => {
        e.preventDefault();
        AuthService.updatePassword(this.state.newPassword)
            .then(response => {
                if (response.status == 200) {
                    window.location.href = "/home"
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    handlePasswordChange = (event) => {
        var validatePass = commonHelper.validatePassword(event.target.value)
        if (!validatePass.result) {
            this.setState({ error: validatePass.msg })
        } else {
            this.setState({ error: null })
        }
        this.setState({ newPassword: event.target.value, isValidPassword: false })
    }
    matchPassword = (e) => {

        if (e.target.value == this.state.newPassword) {
            this.setState({ isValidPassword: true, error: null })
        } else {
            this.setState({ isValidPassword: false })
            this.setState({ error: "Password is not matched" })
        }
        this.setState({ confirmPassword: e.target.value })
    }

    render() {
        const { newPassword, confirmPassword, isValidPassword } = this.state
        return (<div className='md:w-8/12 max-w-xl relative z-10 m-auto px-6 lg:mt-16'>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <FormHeader
                    heading="Change Temporary Password"
                    paragraph=""
                    linkName=""
                    linkUrl=""
                />
                <br>
                </br>

                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                        <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                        <div>
                            <p className="font-bold">Password Requirements</p>
                            <p className="text-sm">
                                MUST contain at least 8 characters (12+ recommended)
                                <br></br>MUST contain at least one uppercase letter
                                <br></br>MUST contain at least one lowercase letter
                                <br></br>MUST contain at least one number
                                <br></br>MUST contain at least one special character</p>
                        </div>
                    </div>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                            New Password
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                            id="newpassword"
                            type="password"
                            value={newPassword}
                            onChange={this.handlePasswordChange}
                            placeholder="New Password"
                            required />

                    </div>
                    <div className="mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                            Confirm Password
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={this.matchPassword}
                            placeholder="Confirm Password"
                            required />
                        {this.state.error && <h2 style={{ color: 'red' }}>{this.state.error}</h2>}
                    </div>
                    <div className="flex items-center justify-between">
                        <button type='submit' disabled={!isValidPassword} className="disabled:bg-gray-600 bg-blue-600 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded">
                            Update Password
                        </button>
                    </div>
                </form>
            </div>
        </div>);
    }
}

export default ChangePassword;