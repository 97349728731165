import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button
} from "@material-tailwind/react";
import { NavLink } from 'react-router-dom';

const Home = () => {
    return (
        <div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
            <div className="pt-8 flex justify-center">
                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/feeds">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/photo-1522096823084-2d1aa8411c13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                Feeds
                            </Typography>
                            <Typography>
                                Start to connect with alumni!
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>

                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/events">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                Events
                            </Typography>
                            <Typography>
                                Join the event.
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>

                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/events">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/photo-1477925181867-37d89ee64b58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1164&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                MeetUps
                            </Typography>
                            <Typography>
                                Start to meet up!
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>

            </div>
            <div className="pt-8 flex justify-center">

                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/mentorship">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/photo-1512238972088-8acb84db0771?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                Mentorship
                            </Typography>
                            <Typography>
                                Join our mentorship program.
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>

                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/#">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                Career
                            </Typography>
                            <Typography>
                                Find out the analysis of job market.
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>

                <Card className="mt-6 w-96 ml-10">
                    <NavLink to="/findAlumni">
                        <CardHeader color="blue-gray" className="relative h-76">
                            <img src="https://images.unsplash.com/45/eDLHCtzRR0yfFtU0BQar_sylwiabartyzel_themap.jpg?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1178&q=80" alt="img-blur-shadow" layout="fill" />
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                Find Alumni
                            </Typography>
                            <Typography>
                                Searching for who is nearby.
                            </Typography>
                        </CardBody>
                    </NavLink>
                </Card>
            </div>
        </div>
    );
}

export default Home;