import axios from "axios";
const isProduction = process.env.NODE_ENV === 'production';


const API_URL = (isProduction ? "" : "http://localhost:3000") + "/api/feed/";

const FeedService = {
  postFeed(message, imageFile) {
    const formData = new FormData();
    formData.append("message", message);

    if (imageFile) {
      formData.append("file", imageFile);
    }

    console.log(formData)
    return axios.post(API_URL + "postFeed", formData, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  postFeedComment(_id, comment) {

    return axios.post(API_URL + _id + "/comment", { comment }, {
      withCredentials: true,
    });
  },
  getFeed() {
    return axios.get(API_URL, {
      withCredentials: true,
    });
  },

  likeFeed(_id) {
    return axios.post(API_URL + _id + "/like", {}, {
      withCredentials: true,
    });
  },

  deleteFeed(_id) {
    return axios.delete(API_URL + _id, {
      withCredentials: true,
    });
  },
  updateFeed(_id, data) {
    return axios.put(API_URL + _id, data, {
      withCredentials: true,
    });
  },
};

export default FeedService;
