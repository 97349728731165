import React, { useEffect, useState } from "react";
import Post from "./posts";
import InputBox from "./inputBox";
import FeedService from "../../services/feed.service";
import commonHelper from "../helper/commonHelper";
import authService from "../../services/auth.service";

const Feeds = () => {
  const [posts, setPosts] = useState([]);
  const [avatar, setAvatar] = useState();
  const [role, setRole] = useState("");


  const getFeed = () => {
    FeedService.getFeed().then((resp) => {
      console.log("all post", resp.data);
      const data = resp.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setPosts(data);
    });
  };

  const getUserName = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        console.log("test", data)
        setRole(data.role)
        setAvatar(data.avatar)
      })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  };

  const handleChildClick = () => {
    getFeed();
  };

  useEffect(() => {
    getFeed();
    getUserName();
  }, []);


  return (
    // <div className="ml-16 mr-16 item-center max-w-screen-lg mx-auto">
    <div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
      <InputBox
        refreshFeed={getFeed}
        avatar={avatar ? avatar : require(`../../images/avatar.jpeg`)}
      />
      <div className="flex flex-col mb-4 pb-4">
        {posts.map((post) => (
          <Post
            key={post._id}
            id={post._id}
            username={
              post.feedOwner
                ? post.feedOwner.username
                : "Server not return uername"
            }
            message={post.message}
            timestamp={post.createdAt}
            selfAvatar={avatar}
            avatar={
              post.feedOwner.avatar ? post.feedOwner.avatar : require(`../../images/avatar.jpeg`)
            }
            imageUrl={post.imageUrl}
            isLike={post.likes.includes(commonHelper.getCookie("userId"))}
            comments={post.comments}
            activateEffect={handleChildClick}
            likeCount={post.likes.length}
            isOwner={post.feedOwner?._id == commonHelper.getCookie("userId") || role == 'staff'}
          />
        ))}
      </div>
    </div>
  );
};

export default Feeds;
