import React, { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import ProfileItem from "../main/profileItem";

const MentorshipAdmin = ({updateData}) => {

const [mentorsData, setMentorsData] = useState([]);
const [editingMentor, setEditingMentor] = useState(null);
  

const getData = async () => {
    await authService.getMentors().then((response) => {
      setMentorsData(response.data);
    });
  };


  useEffect(() => {
    getData();
    }, []);


  const handleEditAccount = (person) => {
     setEditingMentor(person);
  };
  const handleBack = () => {
    setEditingMentor(null);
  };


  return (
    <>
    {editingMentor ? (
        <div>
          <ProfileItem
            user={editingMentor}
            updateProfile={updateData}
             isStaff={true}
          />
          <div className="flex justify-center mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-10 rounded"
              onClick={handleBack}
            >
              Back to Account Lists
            </button>
          </div>
        </div>
      ):
      <div className="content-center mt-3 w-5/6 bg-[#FFFFFF] rounded-lg border-gray-800 ">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="mt-2 mb-2 border-black">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Name
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Title
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Mentor
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Role
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Mentor Request
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold  tracking-wider"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {/* {peopleData.map(person => ( */}
            {mentorsData.map(
              (person) => (
                console.log(person),
                (
                  <tr key={person.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={
                              person.avatar
                                ? person.avatar
                                : require(`../../images/avatar.jpeg`)
                            }
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-base font-extrabold ">
                            {person.firstname}
                            {person.lastname}
                          </div>
                          <div className="text-sm font-semibold text-gray-600">
                            {person.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-base font-semibold">
                        {person.title}
                      </div>
                      <div className="text-sm text-gray-600">
                        {person.company}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-base leading-7 font-semibold rounded-full ${
                          person.isMentor
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-300 text-gray-500"
                        }`}
                      >
                        {person.isMentor ? "Active" : "Inactive"}
                      </span>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-base font-semibold ">
                      {person.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-base leading-7 font-semibold rounded-full ${
                          person.isApprovedMentor
                            ? "bg-green-100 text-green-800"
                            : "bg-red-300 text-white"
                        }`}
                      >
                        {person.isApprovedMentor ? "Approved" : "Pending"}
                      </span>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-base font-medium">
                      <button
                        className="text-blue-500 hover:text-indigo-900 border border-blue-500 px-5 py-2"
                        onClick={() => handleEditAccount(person)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>}
    </>
  );
};

export default MentorshipAdmin;
