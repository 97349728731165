import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import commonHelper from "../helper/commonHelper";


const AccountNew = ({updateAcounts}) => {
  const [username, setUserName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

//   const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      alert("Email is empty");
      return;
    } else if (!commonHelper.isValidEmail(email)) {
      alert("Email is invalid");
      return;
    } else if (!commonHelper.isValidPhone(phoneNum)) {
      setError("Phone number is invalid");
      return;
    }

    AuthService.signup({
      email,
      firstname,
      username,
      lastname,
      phoneNum,
      role,
      password
    })
      .then((response) => {
        console.log("check login");
        console.log(response);
        if (response) {
          setUserName("");
          setFirstname("");
          setLastname("");
          setEmail("");
          setPhoneNum("");
          setPassword("");
          setRole("");
          setError("");
          alert("Account created successfully");
          updateAcounts();
        }
      })
      .catch((error) => {
        if ((error.response.status = 409)) {
          if (error.response.data?.message.includes("email")) {
            setError("Email already exists!! Please try again.");
          } else if (error.response.data?.message.includes("username")) {
            setError("username already exists!! Please try again.");
          } else {
            setError("Something bad happened!!");
          }
        }
      });
  };

  const handleChange = (event) => {
    if (!commonHelper.isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  return (
    <div className="md:w-8/12 max-w-xl relative z-10 m-auto px-6 lg:mt-6">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      
        <div className="text-2xl font-extrabold mt-5 mb-5">Create New Account</div>

        {error && (
          <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-3 mb-3 relative"
            role="alert"
          >
            <p className="font-bold">Error Occured!</p>
            <p>{error}.</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>

        <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              UserName
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
            //   htmlFor="email"
            >
              First Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="fname"
              type="text"
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
              placeholder="First Name"
              required
            />
            
          </div>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
            //   htmlFor="email"
            >
              Last Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="lname"
              type="text"
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
              placeholder="Last Name"
              required
            />
            {/* {this.state.error && <h2 style={{ color: 'red' }}>{this.state.error}</h2>} */}
          </div>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
            //   htmlFor="email"
            >
              Phone Number
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="pno"
              type="tel"
              value={phoneNum}
              // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={(e) => {
                setPhoneNum(e.target.value);
              }}
              placeholder="Phone Number"
              required
            />
           
          </div>

          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
            //   htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="email"
              type="email"
              value={email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
           
          </div>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
            //   htmlFor="email"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              required
            />
            </div>

            <div className="mb-4">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="role">
                    Role
                </label>
                <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    id="role"
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    required
                >
                    <option value="">Select Role</option>
                    <option value="user">User</option>
                    <option value="staff">Staff</option>
                    <option value="admin">Admin</option>
                </select>
            </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
            >
             Create
            </button>


          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountNew;

