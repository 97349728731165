import Header from "./header";
import commonHelper from "../helper/commonHelper";
import { Component, useEffect, useState } from "react";
import authService from "../../services/auth.service";

class HeaderWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { userProfile: {} };
  }
  onLogout = (e) => {
    e.preventDefault();
    console.log("logout");
    commonHelper.logout();
  };

  onLogin = (e) => {
    e.preventDefault();
    console.log("login");
    window.location.href = "/login";
  };

  getUserName = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        console.log(this.state.userProfile)
        this.setState({ userProfile: data });
        return data.username;
      })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  };

  hasJWT = () => {
    let flag = false;
    commonHelper.getCookie("token") ? (flag = true) : (flag = false);

    return flag;
  };
  componentDidMount() {
    this.getUserName();
  }

  render() {
    return (
      <>
        {this.hasJWT() ? (
          <Header
            navUrl="/home"
            btnText="Logout"
            hoverColor="red"
            avatar={this.state.userProfile?.avatar}
            nameInitial={this.state.userProfile?.firstname?.substr(0, 1) + this.state.userProfile?.lastname?.substr(0, 1)}
            username={this.state.userProfile?.username}
            btnAction={this.onLogout}
          />
        ) : (
          <Header
            navUrl="/"
            btnText="Login / Register"
            hoverColor="blue"
            btnAction={this.onLogin}
          />
        )}
      </>
    );
  }
}

export default HeaderWrapper;
