import React, { useEffect, useState } from "react";
import AccountNew from "./accountNew";
import AccountEdit from "./accountEdit";
import authService from "../../services/auth.service";
import ProfileItem from "../main/profileItem";

//   export default function Accounts() {
const Accounts = () => {
  const [peopleData, setPeopleData] = useState([]);
  const updateData = () => {
    authService.getUsers().then((response) => {
      setPeopleData(response.data);
    });
  };
  useEffect(() => {
    updateData();
  }, []);

  const [selectedRole, setSelectedRole] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingPerson, setEditingPerson] = useState(null);

  // Filtered data based on selectedRole and searchTerm
  const filteredData = peopleData.filter((person) => {
    if (
      selectedRole &&
      person.role.toLowerCase() !== selectedRole.toLowerCase()
    ) {
      return false;
    }
    if (
      searchTerm &&
      !(
        person.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.lastname.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) {
      return false;
    }
    return true;
  });

  const handleCreateAccount = () => {
    setShowCreateForm(true);
  };

  const handleBack = () => {
    setShowCreateForm(false);
    setEditingPerson(null);
  };

  const handleEditAccount = (person) => {
    setEditingPerson(person);
  };

  const handleCancelEdit = () => {
    setEditingPerson(null);
  };

  const handleDisable = (person) => {
    const updatedPeople = peopleData.map((p) => {
      if (p.username === person.username) {
        return { ...p, status: p.status === "active" ? "inactive" : "active" };
      }
      return p;
    });
    setPeopleData(updatedPeople);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="bg-[#FFFFFF] px-4 pt-3 pb-4 rounded-sm border flex-1 flex flex-col">
      <div className="flex justify-between items-center mb-5">
        <strong className="text-3xl font-extrabold">Account Lists</strong>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleCreateAccount}
        >
          Create New Account
        </button>
      </div>

      {!editingPerson && !showCreateForm && (
        <div className="flex items-center mb-4 text-xl font-bold mr-5">
          <label htmlFor="roleSelect" className="mr-2 ">
            Search by Role:
          </label>
          <select
            id="roleSelect"
            value={selectedRole}
            onChange={handleRoleChange}
            className="border border-gray-500 rounded py-1 px-2"
          >
            <option value="">All</option>
            <option value="Admin">Admin</option>
            <option value="Staff">Staff</option>
            <option value="User">User</option>
          </select>

          <label htmlFor="searchInput" className="ml-10 mr-2">
            Search by Name:
          </label>
          <input
            type="text"
            id="searchInput"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-500 rounded py-1 px-2 w-1/3"
            placeholder="Search firstname, lastname..."
          />
        </div>
      )}

      {showCreateForm ? (
        <>
          <AccountNew updateAcounts={updateData}/>
          <div className="flex justify-center mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-10 rounded"
              onClick={handleBack}
            >
              Back to Account Lists
            </button>
          </div>
        </>
      ) : editingPerson ? (
        <div>
          {/* <AccountEdit person={editingPerson} onCancelEdit={handleCancelEdit} /> */}
          <ProfileItem
            user={editingPerson}
            updateProfile={updateData}
            isAdmin="true"
          />
          <div className="flex justify-center mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-10 rounded"
              onClick={handleBack}
            >
              Back to Account Lists
            </button>
          </div>
        </div>
      ) : (
        // )}
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table content... */}
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Name
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Title
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Status
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold tracking-wider"
              >
                Role
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold  tracking-wider"
              >
                Action
              </th>

              {/* <th
            scope="col"
            className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
            >
            Disable
          </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {/* {peopleData.map(person => ( */}
            {filteredData.map(
              (person) => (
                console.log(person),
                (
                  <tr key={person.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={
                              person.avatar
                                ? person.avatar
                                : require(`../../images/avatar.jpeg`)
                            }
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-base font-extrabold ">
                            {person.firstname}
                            {person.lastname}
                          </div>
                          <div className="text-sm font-semibold text-gray-600">
                            {person.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-base font-semibold">
                        {person.title}
                      </div>
                      <div className="text-sm text-gray-600">
                        {person.company}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-base leading-5 font-semibold rounded-full ${
                          person.isActive
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-300 text-gray-500"
                        }`}
                      >
                        {person.isActive ? "Active" : "Inactive"}
                      </span>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-base font-semibold ">
                      {person.role}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-base font-medium">
                      <button
                        className="text-blue-500 hover:text-indigo-900 border border-blue-500 px-5 py-2"
                        onClick={() => handleEditAccount(person)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Accounts;
