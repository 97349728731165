import { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import { Route, Navigate } from "react-router-dom";
import commonHelper from "../helper/commonHelper";
import SideBar from "../navigation/sideBar";

const RouteGuardAdmin = ({ component: Component, ...rest }) => {
  const [admin, setAdmin] = useState(false)
  function hasJWT() {
    let flag = false;
    commonHelper.getCookie('token') ? (flag = true) : (flag = false);

    return flag;
  }

  const getAdmin = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setAdmin(data?.role == "admin")
      })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  };

  useEffect(() => {
    getAdmin();
  }, []);

  if (hasJWT())
    return (admin && (<>
      <SideBar />
      <main className="flex-1 overflow-y-auto">
        <Component {...rest} />
      </main>
    </>))
  else
    return (<Navigate to={{ pathname: "/login" }} />)
  //   return (hasJWT() & admin) ? (
  //     <>
  //       <SideBar />
  //       <main className="flex-1 overflow-y-auto">
  //         <Component {...rest} />
  //       </main>
  //     </>

  //   ) : (
  //     <>
  //       <SideBar />
  //       <main className="flex-1 overflow-y-auto">
  //         not found
  //       </main>
  //     </>
  //   );
};

export default RouteGuardAdmin;
