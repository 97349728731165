import axios from "axios";
const isProduction = process.env.NODE_ENV === 'production';


const API_URL = (isProduction ? "" : "http://localhost:3000") + "/api/user/";

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "login", {
        email,
        password,
      }, {
        withCredentials: true
      });
  }

  register(username, firstname, lastname, email, password, phoneNum) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  forgetPassword(email) {
    return axios.post(API_URL + "resetPassword", {
      email,
    });
  }

  updatePassword(newPassword) {
    return axios.post(API_URL + "updatePassword", {
      password: newPassword
    }, { withCredentials: true })
  }

  signup(user) {
    return axios.post(API_URL + "register", user)
  }

  getUserProfile() {
    return axios.get(API_URL + "userProfile", {
      withCredentials: true
    })
  }

  getUsers() {
    return axios.get(API_URL + "getusers", {
      withCredentials: true
    })
  }

  async updateUserProfile(Profile) {
    console.log(Profile)
    return await axios.put(API_URL + "updateProfile", { ...Profile }, {
      withCredentials: true
    })
  }
  updateProfilePic(formData) {
    return axios.post(API_URL + "updateUserPic", formData, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  updateLocation(newLocation) {
    return axios.post(API_URL + "updateLocation", {
      location: newLocation
    }, { withCredentials: true })
  }

  getLocation() {
    return axios.get(API_URL + "getLocation", { withCredentials: true })
  }

  getMentors() {
    return axios.get(API_URL + "getMentors",  { withCredentials: true })
  }
  

}

export default new AuthService();
