import React from 'react';
import Laptop from '../../images/analytics.jpeg';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#000000] font-bold '>DATA ANALYTICS DASHBOARD</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Data Analytics on Job Market Trend</h1>
          <p>
            We provide job listings sourced directly from reputable and verified sources,
            ensuring that the job data available on our platform are genuine and reliable.
            Our system also offers comprehensive data analysis on job market trends and
            salary information, providing valuable insights for our alumni job seekers.
          </p>
          <a href='/signup'>
            <button className='bg-[#000000] text-[#ffffff] w-[200px] rounded-xl font-bold my-6 mx-auto md:mx-0 py-3'>Get Started</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Analytics;