import axios from "axios";

const isProduction = process.env.NODE_ENV === 'production';


const API_URL = (isProduction ? "" : "http://localhost:3000") + "/api/event/";

const eventService = {
  getEvents(eventId) {
    return axios.get(API_URL + ((eventId) ? eventId : ""), {
      withCredentials: true,
    });
  },
  getMeetups() {
    return axios.get(API_URL + "getMeetups", {
      withCredentials: true,
    });
  },
  addMeetups(meetUp) {
    return axios.post(API_URL + "addEvent", meetUp, {
      withCredentials: true,
    });
  },
  joinEvent(eventId) {
    return axios.post(API_URL + eventId + "/addAttendee", {}, {
      withCredentials: true,
    });
  },
  deleteEvent(_id) {
    return axios.delete(API_URL + _id, {
      withCredentials: true,
    });
  },
  updateEvent(_id, data) {
    return axios.put(API_URL + _id, data, {
      withCredentials: true,
    });
  },
};
export default eventService