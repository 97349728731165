
import React, { useEffect, useState } from "react";
import JobService from "../../services/job.service";
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from "recharts";


const DashboardBarChart = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        JobService.getBarChart().then((resp) => {
            console.log(resp.data);
            const data = resp.data;
            setData(data);
        });
    }, []);

    return (
        <div className="h-[22rem] bg-[#FFFFFF] p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
            <strong className="text-gray-700 font-medium">Job details</strong>

            <div className="w-full mt-3 flex-1 text-xs ">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 10,
                            left: -10,
                            bottom: 0

                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
                        <XAxis dataKey="Month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Partime" fill="#3ef5e9" />
                        <Bar dataKey="Fulltime" fill="#cd5a0c" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default DashboardBarChart;

