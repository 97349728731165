const commonHelper = {

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    isLoggedIn() {
        this.getCookie('token')

    },
    logout() {
        this.setCookie('token', '', '0')
        window.location.href = window.location.href;
    },
    validatePassword(password) {
        if (password.length < 8) {
            return { result: false, msg: "Password should be atleast 8 character long" };
        }
        if (!/[A-Z]/.test(password)) {
            return { result: false, msg: "Atleast one uppercase Letter." };
        }
        if (!/[a-z]/.test(password)) {
            return { result: false, msg: "Atlease one lower case letter" };
        }
        if (!/[!@#$%^&*()]/.test(password)) {
            return { result: false, msg: "Atleast one symbol." };
        }
        return { result: true, msg: "Validation done" };
    },
    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    },
    isValidPhone(phone) {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)
    },
    getFormattedPhoneNum(input) {
        let output = "(";
        input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
            if (g1.length) {
                output += g1;
                if (g1.length == 3) {
                    output += ")";
                    if (g2.length) {
                        output += " " + g2;
                        if (g2.length == 3) {
                            output += " - ";
                            if (g3.length) {
                                output += g3;
                            }
                        }
                    }
                }
            }
        }
        );
        return output;
    }
}

module.exports = commonHelper