import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button
} from "@material-tailwind/react";
import { NavLink } from 'react-router-dom';

import React, { useEffect, useState } from "react";
import JobService from "../../services/job.service";



const Jobs = () => {

  const [jobData, setJobData] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedJobs, setExpandedJobs] = useState([]);

  const handleToggleDescription = (jobId) => {
    if (expandedJobs.includes(jobId)) {
      setExpandedJobs(expandedJobs.filter((id) => id !== jobId));
    } else {
      setExpandedJobs([...expandedJobs, jobId]);
    }
  };

  const isJobDescriptionExpanded = (jobId) => expandedJobs.includes(jobId);

  const getDescriptionPreview = (job) => {
    const words = job.desc.split(" ");
    const preview = words.slice(0, 30).join(" ");
    return preview + (words.length > 30 ? " ..." : "");
  };

  const getJob = () => {
    JobService.getJob().then((resp) => {
      console.log(resp.data);
      const data = resp.data.sort(
        (a, b) => new Date(b.jobs_ago) - new Date(a.jobs_ago)
      );
      setJobData(data);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(searchTerm)
    JobService.searchJob(searchTerm).then((resp) => {
      console.log(resp.data);
      const data = resp.data.sort(
        (a, b) => new Date(b.jobs_ago) - new Date(a.jobs_ago)
      );
      setJobData(data);
    });
  }


  useEffect(() => {
    getJob();
  }, []);

  return (

    <div className="bg-gray-300 px-4 pt-3 pb-4 rounded-sm border border-gray-400 flex-1 justify-center">

      <form className="w-4/5 mx-auto mt-8" onSubmit={handleSubmit}>
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search by company or job title"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required />
          <button type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Search</button>
        </div>
      </form>
      <div className="pt-8 flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mt-6 mb-6 w-2/3">
          {jobData.map((job) => (
            <Card
              key={job.job_id}
              className="gray-300 shadow mt-6 mb-6 border border-gray-400"
            >
              <CardBody>
                <a href={job.job_href}>
                  <Typography variant="h3" color="blue" className="mb-2 text-3xl font-bold underline">
                    {job.title}
                  </Typography>
                </a>
                <Typography className="mb-2 text-2xl font-bold">{job.company}</Typography>
                <Typography className="mb-2 font-bold">{job.jobs_ago}</Typography>
                <Typography className="mb-2">
                  {isJobDescriptionExpanded(job.job_id) ? job.desc : getDescriptionPreview(job)}
                </Typography>
                <CardFooter>
                  {isJobDescriptionExpanded(job.job_id) ? (
                    <Button
                      color="gray"
                      onClick={() => handleToggleDescription(job.job_id)}
                    >
                      Less
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleToggleDescription(job.job_id)}
                    >
                      More
                    </Button>
                  )}
                </CardFooter>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </div>

  );
}

export default Jobs;