import React from 'react';

const Newsletter = () => {
  return (
    <div className='w-full py-16 bg-[#FFFFFF] text-black px-4'>
      <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4 text-left'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-1 md:py-6  text-left'>
            STAY CONNECTED
          </h1>
          <p>Stay up-to-date with upcoming events, exclusive offers, and the latest news from AlumniHub.</p>
        </div>
        <div className='my-4'>
          <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
            <input
              className='p-3 flex w-full rounded-md text-black bg-slate-200 border-8'
              type='email'
              placeholder='Enter Email'
            />
          </div>

          <button className='bg-[#000000] text-white rounded-md font-bold w-[200px] my-6 px-6 py-3'>
            Notify Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;