import React, { useState } from "react";
import { Route, Link, Routes, NavLink } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import EventModal from "./eventModal";
import eventService from "../../services/event.service";
import commonHelper from "../helper/commonHelper";
import Spinner from "../helper/spinner";

const EventsList = ({
  eventList,
  header,
  isCalendar,
  isCreated,
  updateEvents,
  role,
  isMeetup
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const clickCreate = () => {
    setToggleModal(true);
  };

  const ClickJoin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(eventList)
    eventService
      .joinEvent(e.target.id)
      .then((res) => {
        eventList.forEach(element => {
          if (element._id == e.target.id)
            element.isAttendee = !element.isAttendee
        });
        //updateEvents()
        setIsLoading(false);
      })
      .catch((e) => {
        updateEvents()
        setIsLoading(false);
        console.log(e);
      });
  };

  const handleDelete = (id) => {
    console.log("click delete")
    console.log(id)

    eventService.deleteEvent(id).then((res) => {
      updateEvents()
    })
      .catch((e) => {
        console.log(e);
      });
  }

  console.log(eventList)

  return (
    <div className="max-w-[1240px] w-full mx-auto flex flex-col">
      <h1 className="mb-4 mt-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
        {header}.
      </h1>
      <div className="flex justify-between items-center p-4">
        <h3 className="text-base font-bold text-gray-800 text-center">
          Choose from dozens of {header} across the globe and online
        </h3 >
        <div>
          {isCalendar && (
            <NavLink
              to="/calendar"
              className="align-middle select-none font-sans font-bold uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-blue-500 text-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none text-center"
            >
              Calendar View
            </NavLink>
          )}
          {isCreated && (
            <Button onClick={clickCreate} className="text-center ml-4">
              Create
            </Button>
          )}
        </div>
      </div>
      <table className="table-auto border-collapse w-full mt-8 sm:mt-4 lg:mt-4 border-blue-gray-200">
        <tbody>
          {eventList.map((event, index) => (
            <tr key={index} className="border-b">
              <td className="text-[#ef8437] text-4xl font-bold py-4 flex items-center justify-center">
                <div>{new Date(event.eventDateTime).toLocaleString()}</div>
              </td>
              <td className="py-4 px-6">
                <Link
                  className="font-medium text-[#ef8437] dark:text-[#ef8437]-200 hover:underline"
                  key={event._id}
                  to={`/events/${event._id}`}
                >
                  <div className="event-location text-2xl">{event.title}</div>
                </Link>
                <div className="event-title mt-4 text-blue-gray-800">
                  {event.location}
                </div>
              </td>
              <td className="py-4 px-6 flex items-center justify-center">
                {!event.isExpired ? (<button
                  disabled={isLoading}
                  className={
                    event.isAttendee
                      ? "bg-transparent hover:bg-[#ef8437] text-[#ef8437] font-semibold hover:text-white py-2 px-4 border border-[#ef8437] hover:border-transparent rounded"
                      : "bg-[#ef8437] hover:bg-white text-white font-semibold hover:text-[#ef8437] py-2 px-4 border border-transparent hover:border-[#ef8437] rounded"
                  }
                  id={event._id}
                  onClick={ClickJoin}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : !event?.isAttendee ? (
                    "join"
                  ) : (
                    "joined"
                  )}
                </button>):<button className="bg-transparent hover:bg-[#FF0000] text-[#FF0000] font-semibold hover:text-white py-2 px-4 border border-[#FF0000] hover:border-transparent rounded" disabled={true}>Expired</button>}
              </td>
              {(event.EventOwner?._id == commonHelper.getCookie("userId") || role == "staff") &&
                <td className="py-4 px-6">
                  <button type="button" onClick={() => handleDelete(event._id)}>
                    <svg className="w-2 h-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                  </button>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>

      {toggleModal && (
        <EventModal setModalOn={setToggleModal} updateEvents={updateEvents} isMeetup={isMeetup} />
      )}
    </div>
  );
};

export default EventsList;
