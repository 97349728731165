import "./App.css";
import React from "react";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import RouteGuard from "./components/login/RouteGuard";
import RouteGuardAdmin from "./components/login/RouteGuardAdmin";
import Header from "./components/navigation/header";
import Home from "./components/main/home";
import Feeds from "./components/feeds/feeds";
import Dashboard from "./components/dashboard/dashboard";
import Events from "./components/events/events";
import Jobs from "./components/career/jobs";
import Profile from "./components/main/profile";
import Mentorship from "./components/career/mentorship";
import FindAlumni from "./components/main/findAlumni";
import Help from "./components/main/help";
import Donation from "./components/donation/donation";
import Login from "./components/login/login";
import IntroPage from "./pages/Intro";
import ForgetPassword from "./components/login/forgetPassword";
import ChangePassword from "./components/login/changePassword";
import RouteGuardWithoutSidebar from "./components/login/RouteGuardWithoutSidebar";
import SignUp from "./components/login/signup";
import HeaderWrapper from "./components/navigation/headerWrapper";
import EventsItem from './components/events/eventsItem';
import EventCalendar from "./components/events/eventCalendar";
import Accounts from "./components/admin/accounts";
import DonationAdmin from "./components/admin/donationAdmin";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <HeaderWrapper />
      <div className="flex h-full">

        <Routes>
          {/* <Route path="/login2" element={<LoginPage />} />
            <Route path="/login" element={<Login />} /> */}
          <Route path="/" element={<IntroPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp />} />

          <Route
            path="/changePassword"
            element={<RouteGuardWithoutSidebar component={ChangePassword} />}
          />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/home" element={<RouteGuard component={Home} />} />
          <Route path="/feeds" element={<RouteGuard component={Feeds} />} />
          <Route path="/events/*" element={<RouteGuard component={Events} />} />
          <Route path="/events/:id" element={<RouteGuard component={EventsItem} />} />
          <Route path="/calendar" element={<RouteGuard component={EventCalendar} />} />
          <Route path="/dashboard" element={<RouteGuard component={Dashboard} />} />
          <Route path="/jobs" element={<RouteGuard component={Jobs} />} />
          <Route path="/profile" element={<RouteGuard component={Profile} />} />
          <Route
            path="/mentorship"
            element={<RouteGuard component={Mentorship} />}
          />
          <Route
            path="/findAlumni"
            element={<RouteGuard component={FindAlumni} />}
          />
          <Route path="/help" element={<RouteGuard component={Help} />} />
          <Route path="/donation" element={<RouteGuard component={Donation} />} />

          <Route
            path="/accounts"
            element={<RouteGuardAdmin component={Accounts} />}
          />

          <Route
            path="/donationAdmin"
            element={<RouteGuardAdmin component={DonationAdmin} />}
          />
        </Routes>

      </div>
    </div>
  );
}

export default App;
