const Comments = ({ comment, createdAt, user }) => {
  console.log(user)
  return (
    <div className="flex items-center space-x-4 p-2 ">
      <img
        src={user?.avatar ? user.avatar : require(`../../images/avatar.jpeg`)}
        alt="user"
        className="h-10 w-10 rounded-full object-cover icon"
        loading="lazy"
      />

      <div className="w-72 flex flex-1">
        <span className="font-bold">{user?.username}:  </span>
        <span>{comment}</span>
      </div>
      <div className="text-gray-500 text-sm">{new Date(createdAt).toLocaleString()}</div>
    </div>
  );
};

export default Comments;
