import axios from "axios";

const isProduction = process.env.NODE_ENV === 'production';


const API_URL =  (isProduction?"":"http://localhost:3000") + "/api/payment/";

class DonationService {
   getDonations() {
    return axios
      .get(API_URL + "getPayments", {
      }, {
        withCredentials: true
      });
  }
 

}

export default new DonationService();
