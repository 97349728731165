import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='w-full mx-auto py-16 px-16 grid lg:grid-cols-4 gap-8 text-gray-300 bg-black'>
      <div>
        <h1 className='w-full text-3xl font-bold text-white text-left'>AlumniHub</h1>
        <p className='text-xl text-white py-1'>Douglas College</p>
        <p className='py-2'>Your alumni association, tailored for college graduates like you. Our strength? 
                            The collective power of alumni. 
                            Join a community that's truly yours to engage with and gain from.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            <FaGithubSquare size={30} />
        </div>
      </div>
      {/* <div className='lg:col-span-2 flex justify-between mt-6'> */}
        <div>
            <h6 className='font-bold text-white'>Contact Us</h6>
            <ul>
                <li className='py-2 text-sm'>General: 618 012 3456</li>
                <li className='py-2 text-sm'>International: 618 012 3457</li>
                <li className='py-2 text-sm'>Email: alumnihub4495@gmail.com</li>
            </ul>
        </div>
        <div>
            <h6 className='font-bold text-white'>Useful Links</h6>
            <div>
                <a href='/home' className='py-2 text-sm'>Home</a>
            </div>
        </div>
        <div>
            <h6 className='font-bold text-white'>Other Links</h6>
            <div>
            <p><a href='https://www.douglascollege.ca/' className='py-2 text-sm'>Our Campus</a></p>
            <p><a href='https://library.douglascollege.ca/' className='py-2 text-sm'>College Library</a></p>
            <p><a href='https://www.douglascollege.ca/student-services' className='py-2 text-sm'>Student Services</a></p>
            <p><a href='https://douglascollege.blackboard.com/' className='py-2 text-sm'>BlackBoard</a></p>
        </div>
    </div>
      </div>
    // </div>
  );
};

export default Footer;