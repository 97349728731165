import { Link } from 'react-router-dom';

const FormHeader = ({
	heading,
	paragraph,
	linkName,
	linkUrl
}) => {

	return (
		<div className="mt-10">
			<div className="flex justify-center items-center">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-20 w-20">
					<circle cx="4" cy="12" r="3" fill="#4285F4" />
					<circle cx="8" cy="8" r="3" fill="#34A853" />
					<circle cx="12" cy="4" r="3" fill="#FBBC05" />
					<circle cx="16" cy="8" r="3" fill="#EB4335" />
					<circle cx="20" cy="12" r="3" fill="#FFBA00" />
					<circle cx="12" cy="18" r="3" fill="#4C75A3" />
				</svg>
			</div>
			<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
				{heading}
			</h2>
			<p className="mt-2 text-center text-sm text-gray-600 mt-5">
				{paragraph} {' '}
				<a href={linkUrl} className="font-medium text-purple-600 hover:text-purple-500">
					{linkName}
				</a>
			</p>
		</div>);
}

export default FormHeader;