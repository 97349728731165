import React, { useEffect, useState, Component } from "react";
import "./donation.css";
import { Helmet } from 'react-helmet';

const Donation = () => {
  // state = {
  //   amount: 100,
  //   open: true,
  //   hasMounted: false,
  //   paymentCard: {},
  // };

  const [amount, setAmount] = useState(100);
  const [open, setOpen] = useState(true);
  const [hasMounted, setHasMounted] = useState(false);
  const [paymentCard, setPaymentCard] = useState({});


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "./payment.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // "payment.js" is now loaded, and you can safely call `window.scriptLoaded()`
      window.scriptLoaded();
    };

    return () => {
      // Cleanup function to remove the script if the component unmounts
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="donation">
      <Helmet>
        {/* <script src="./payment.js"></script> */}
        <script src="https://sandbox.web.squarecdn.com/v1/square.js" type="text/javascript"></script>
      </Helmet>
      <section className="antialiased bg-gray-100 text-gray-600 min-h-screen p-4">
        <div className="h-full">
          <div>
            <div className="relative px-4 sm:px-6 lg:px-8 max-w-lg mx-auto">
              {/* <img className="rounded-t shadow-lg" src="https://preview.cruip.com/mosaic/images/pay-bg.jpg" width="460" height="180" alt="Pay background" /> */}
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8 pb-8 max-w-lg mx-auto">
              <div className="bg-white px-8 pb-6 rounded-b shadow-lg">
                <div className="pt-4 text-center mb-6">
                  <h1 className="text-xl leading-snug text-gray-800 font-semibold mb-2">
                    Donate to Contribute
                  </h1>
                </div>{" "}
                {
                  <div>
                    <div className="space-y-4">
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="card-Amount"
                        >
                          Amount <span className="text-red-500">*</span>
                        </label>
                        <input
                          id="card-Amount"
                          className="text-sm text-gray-800 bg-white border rounded leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm placeholder-gray-400 focus:ring-0 w-full"
                          type="number"
                          value={amount}
                          onChange={(e) =>
                            this.setState({ amount: e.target.value })
                          }
                          placeholder="$100"
                        />
                      </div>
                      <form id="payment-form">
                        <div id="card-container"></div>
                        <button
                          className="font-medium text-sm inline-flex items-center justify-center px-3 py-2 border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out w-full bg-indigo-500 hover:bg-indigo-600 text-white focus:outline-none focus-visible:ring-2"
                          id="card-button"
                          type="button"
                        >
                          Pay
                        </button>
                      </form>
                    </div>
                    <div className="mt-6">
                      <div id="payment-status-container"></div>
                      <div className="text-xs text-gray-500 italic text-center">
                        Thank you
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Donation;
