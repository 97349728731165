// Parent component
import React, { useState, useEffect } from "react";
import ProfileItem from "./profileItem";
import authService from "../../services/auth.service";

const Profile = () => {
  const [userProfile, setUserProfile] = useState([]);

  const getprofile = () => {
    authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setUserProfile(data);
      })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  };
  useEffect(() => {
    getprofile();
  }, []);

  return (
    <div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
      {/* Render the Profile component and pass the user prop */}
      <ProfileItem user={userProfile} updateProfile={getprofile} />
    </div>
  );
};

export default Profile;
