import React from "react";
import DashboardStatsGrid from "./dashboardStatsGrid";
import DashboardBarChart from "./dashboardBarChart";
import DashboardPieChart from "./dashboardPieChart";
import DashboardLeftTable from "./dashboardLeftTable";
import DashboardRightTable from "./dashboardRightTable";

const Dashboard = () => {
    return (
        <div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
            <div className='flex flex-col gap-4 bg-gray-300'>
                <DashboardStatsGrid />
                <div className="flex flex-row gap-4 w-full">
                    <DashboardBarChart />
                    <DashboardPieChart />
                </div>
                <div className="flex flex-row gap-4 w-full">
                    <DashboardLeftTable />
                    <DashboardRightTable />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
