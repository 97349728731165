import React, { useEffect, useState } from "react";
import donationService from "../../services/donation.service";

const DonationAdmin = () => {
  const [donations, setDonations] = useState([]);
  const [totaldonation, setTotalDonation] = useState(0);

  const updateDonations = async () => { donationService.getDonations().then((resp) => {
    setDonations(resp.data);
    setTotalDonation(+resp.data.reduce((a, b) => a + (+b["amount"]), 0))
  })};

  useEffect(() => {
    updateDonations()
  },[]);

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="bg-[#FFFFFF] px-4 pt-3 pb-4 rounded-sm border flex-1 flex flex-col items-center justify-center">
      <div className="flex justify-left items-center mb-5">
        <strong className="text-3xl font-extrabold">Donation Details</strong>
      </div>

      <div className="content-center mt-3 w-5/6 bg-[#FFFFFF] rounded-lg border-gray-800 ">
        {/* <table className="w-full text-black font-2xl text-center border-gray-500 mt-5 mb-5 shadow-orange-600" >
            <thead> */}
        <table className="min-w-full divide-y divide-gray-200">
          {/* Table content... */}
          <thead className="bg-gray-50">
            <tr className="mt-2 mb-2 border-black">
              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold  tracking-wider"
              ></th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold   tracking-wider"
              >
                UserName
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold   tracking-wider"
              >
                Name
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold   tracking-wider"
              >
                Date
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xl font-bold   tracking-wider"
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {donations.map((donation) => (
              <tr key={donation._id}>
                <td className="px-6 py-4 whitespace-nowrap text-base font-semibold">
                  <div className="flex-shrink-0 h-10 w-10">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={donation.PaymentUser.avatar}
                      alt=""
                    />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-base font-semibold">
                  {donation.PaymentUser.username}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-base font-semibold">
                  {donation.PaymentUser.firstname}{" "}
                  {donation.PaymentUser.lastname}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-base font-semibold">
                  {new Date(donation.createdAt).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-base font-semibold">
                  ${+donation.amount}
                </td>
              </tr>
            ))}

            <tr>
              <td
                className="px-6 py-4 whitespace-nowrap text-2xl font-extrabold  text-right"
                colSpan="4"
              >
                Total Amount:
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-2xl font-extrabold "
                colSpan="1"
              >
                ${totaldonation}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DonationAdmin;
