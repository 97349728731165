import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import background from "../../images/background.jpg";
import AuthService from "../../services/auth.service";
import FormHeader from "./formHeader";

const Login = ({ setSideBarVisibility = (f) => f }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is empty");
      return;
    } else if (!password) {
      setError("Password is empty");
      return;
    } else if (!isValidEmail(email)) {
      setError("Email is invalid");
      return;
    }

    AuthService.login(email, password)
      .then((response) => {
        // console.log("-----------------check login");
        console.log(response)
        if (response?.data.isTempPassword) {
          window.location.href = "/changePassword";
        } else if (response?.data.role == 'admin') {
          window.location.href = "/accounts";
          // console.log("admin")
        }
        else {
          window.location.href = "/home";
          // console.log("user")
        }
      })
      .catch((error) => {
        if ((error.response.status = 409)) {
          setError("Email/Password is incorrect! Please try again.")
        } else {
          setError("Please try again.")
        }
      });
  };

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  return (
    <div className="md:w-8/12 max-w-xl relative z-10 m-auto px-6 lg:mt-16" >
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
        <FormHeader
          heading="Login to your account"
          paragraph="Don't have an account yet? "
          linkName="Signup"
          linkUrl="/signup"
        />

        {error && (
          <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-3 mb-3 relative"
            role="alert"
          >
            <p className="font-bold">Error Occured!</p>
            <p>{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="email"
              type="text"
              value={email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
            {/* {error && <h2 style={{ color: "red" }}>{error}</h2>} */}
          </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
              id="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="******************"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
            >
              Login
            </button>
            <a
              className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
              href="/forgetPassword"
            >
              Forgot Password
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
