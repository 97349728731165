import { useEffect, useState } from "react";
import EventsList from "./eventsList";
import eventService from "../../services/event.service";
import authService from "../../services/auth.service";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [meetUps, setMeetUps] = useState([]);
  const [role, setRole] = useState("");

  const updateEvents = async () => {
    await eventService.getEvents().then((resp) => {
      setEvents(
        resp.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    });
    await eventService.getMeetups().then((resp) => {
      setMeetUps(
        resp.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    });
  };
  useEffect(() => {
    updateEvents();
    getRole()
  }, []);

  const getRole = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setRole(data.role)
        console.log(role)
      })
      .catch((error) => {
        console.error("Error retrieving role:", error);
      });
  };

  return (
    <div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
      <EventsList
        eventList={events}
        header="Events"
        isCalendar={true}
        isCreated={role == "staff"}
        updateEvents={updateEvents}
        role={role}
        isMeetup={false}
      />
      <EventsList
        eventList={meetUps}
        header="Meetups"
        isCreated={true}
        updateEvents={updateEvents}
        role={role}
        isMeetup={true}
      />
    </div>
  );
};

export default Events;
