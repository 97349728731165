const NearByAlumni = ({ alumniList, long, lat }) => {
	function calculateDistance(lat1, lon1, lat2, lon2) {
		const R = 6371; // Radius of the Earth in kilometers
		const dLat = deg2rad(lat2 - lat1);
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = R * c;
		return distance;
	}

	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}


	return (
		<div className="mt-8 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
			<div className="flex items-center justify-between mb-4">
				<h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Nearby Alumni</h5>
			</div>
			<div className="flow-root">
				<ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
					{alumniList.map((alumni) => <li key={alumni._id} className="py-3 sm:py-4">
						<div className="flex items-center space-x-4">
							<div className="flex-shrink-0">
								<img className="w-12 h-12 rounded-full" src={alumni.avatar ? alumni.avatar : require(`../../images/avatar.jpeg`)} alt="image" />
							</div>
							<div className="flex-1 min-w-0">
								<p className="text-sm font-medium text-gray-900 truncate dark:text-white">
									{alumni.firstname} {alumni.lastname}
								</p>
								{/* <p className="text-sm text-gray-500 truncate dark:text-gray-400">
									email@windster.com
								</p> */}
							</div>
							<div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
								Distance: {calculateDistance(lat, long, alumni.location.latitude, alumni.location.longitude).toFixed(2)} km
							</div>
						</div>
					</li>
					)}

				</ul>
			</div>
		</div>
	)
}

export default NearByAlumni;