import React, { useEffect, useState } from 'react';
import commonHelper, { getCookie } from '../helper/commonHelper';
import authService from '../../services/auth.service';
import NearByAlumni from './nearByAlumni';

const GoogleMapsAPI = ({ apiKey }) => {
	useEffect(() => {
		if (!window.google) {
			const script = document.createElement('script');
			script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
			script.async = true;
			script.defer = true;
			script.onload = () => {
				// The Google Maps API has loaded successfully
				console.log('Google Maps API loaded.');
			};
			script.onerror = () => {
				console.error('Error loading Google Maps API.');
			};
			document.head.appendChild(script);
		}

		return () => {
			// Clean up the dynamically added script when the component unmounts
			const scripts = document.getElementsByTagName('script');
			for (let i = 0;i < scripts.length;i++) {
				const script = scripts[i];
				if (script.src === `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`) {
					document.head.removeChild(script);
					break;
				}
			}
		};
	}, [apiKey]);

	return null;
};



const FindAlumni = () => {
	const [userLocation, setUserLocation] = useState(null);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [alumniData, setAlumniData] = useState([]);
	const [map, setMap] = useState(null);
	const [userMarker, setUserMarker] = useState(null);
	const [alumniMarkers, setAlumniMarkers] = useState([]);

	const filteredAlumni = alumniData.filter((alumni) =>
		alumni.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
		alumni.lastname.toLowerCase().includes(searchTerm.toLowerCase())
	);

	useEffect(() => {
		// Update markers on the map when the search term changes
		if (map && userMarker && alumniMarkers.length > 0) {
			updateMarkers();
		}
	}, [searchTerm]);

	const updateMarkers = () => {
		// Hide all alumni markers
		alumniMarkers.forEach((marker) => {
			marker.setMap(null);
		});

		// Show only the markers for filtered alumni
		const filteredMarkers = alumniMarkers.filter((marker, index) =>
			filteredAlumni.includes(alumniData[index])
		);

		filteredMarkers.forEach((marker) => {
			marker.setMap(map);
		});
	};


	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setUserLocation({ latitude, longitude });
					console.log(getCookie("userId"));
					// update my location to db
					authService.updateLocation({ latitude, longitude })
						.then((response) => {
							console.log("get new location");
							console.log(response);
						})
						.catch((error) => {
							console.log(error)
						});
					//get alumni data
					authService.getLocation().then((res) => {
						const data = res.data;
						console.log(data)
						const filterData = data.filter(x => x._id !== commonHelper.getCookie("userId"));
						console.log(filterData)
						// remove self location
						setAlumniData(filterData)
						console.log("alumni data", alumniData)
					}).catch((error) => {
						console.error("Error retrieving user location:", error);
					});
					setLoading(false);
				},
				() => {
					console.error('Error retrieving user location.');
					setLoading(false);
				}
			);
		} else {
			console.error('Geolocation is not supported by this browser.');
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		// Render the map once the user location and alumni data are available
		if (userLocation && !loading && alumniData) {
			renderMap();
		}
	}, [userLocation, loading, alumniData]);


	const renderMap = () => {
		if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
			let myLatLng = new window.google.maps.LatLng(userLocation.latitude, userLocation.longitude);
			const mapOptions = {
				zoom: 10,
				center: myLatLng,
			};

			const newMap = new window.google.maps.Map(document.getElementById('map'), mapOptions);
			setMap(newMap);


			// Add info windows for alumni markers
			const newAlumniMarkers = alumniData.map((alumni) => {
				const alumniMarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(alumni.location.latitude, alumni.location.longitude),
					map: newMap,
					title: alumni.firstname + " " + alumni.lastname,
				});

				// Create an info window for each alumni marker
				const infoWindow = new window.google.maps.InfoWindow({
					content: `<div><strong>${alumni.firstname} ${alumni.lastname}</strong></div>`,
				});

				// Add click event listener to display the info window when marker is clicked
				alumniMarker.addListener("click", () => {
					infoWindow.open(newMap, alumniMarker);
				});

				return alumniMarker;
			});
			setAlumniMarkers(newAlumniMarkers);


			// Add a marker for your location
			const newMarker = new window.google.maps.Marker({
				position: myLatLng,
				map: newMap,
				title: 'Your Location',
				icon: {
					url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
				},
			});
			setUserMarker(newMarker);

			console.log("map", alumniData)
		} else {
			console.error('Google Maps API is not loaded.');
		}
	};


	return (
		<div >

			<div className="flex justify-center items-center mt-2 mb-6 bg-white">
				<input
					type="text"
					className="w-2/5 px-3 py-2 mt-5 mb-2 border border-gray-800  rounded-md"
					placeholder="Search by name ..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</div>


			{/* Render Google Maps API */}
			<GoogleMapsAPI apiKey="AIzaSyCaKtDRMj0-MZLdlKsazkmbUdWrTdVwigA" />

			{/* Render the map */}
			{userLocation && <div
				id="map"
				className="w-4/5 mx-auto mt-8"
				style={{ height: '60vh' }}
			></div>
			}


			{/* Alumni list */}
			<div className="w-4/5 h-screen mx-auto mt-8 text-2xl">
				{loading ? (
					<div role="status " className='flex justify-center items-start'>
						<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
						<span className="sr-only">Loading...</span>
					</div>
				) : userLocation ? (

					<NearByAlumni alumniList={filteredAlumni} long={userLocation.longitude} lat={userLocation.latitude} />


				) : (
					<div className="text-center bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-3 mb-3 relative"
						role="alert">
						<p>Unable to retrieve user location.</p>
					</div>

				)}
			</div>
		</div>

	);
};


export default FindAlumni;