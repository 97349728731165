import { ColorSwatchIcon } from "@heroicons/react/outline";
import logo from "../../images/logo.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Header = ({
  navUrl,
  btnText,
  hoverColor,
  nameInitial,
  username,
  avatar,
  btnAction = (f) => f,
}) => {
  const hovercss =
    hoverColor === "red" ? "hover:bg-red-800" : "hover:bg-blue-800";

  const btncss =
    "ml-4 px-4 py-2 font-semibold text-white rounded mr-4 " + hovercss;

  return (
    <div className="sticky top-0 bg-bkg-blue z-50 flex items-center justify-between">
      <div className="flex items-center">
        <NavLink
          to={navUrl}
          className="flex items-center text-white rounded-lg p-3 hover:bg-gray-900 transition-colors duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="h-10 w-10"
          >
            <circle cx="4" cy="12" r="3" fill="#4285F4" />
            <circle cx="8" cy="8" r="3" fill="#34A853" />
            <circle cx="12" cy="4" r="3" fill="#FBBC05" />
            <circle cx="16" cy="8" r="3" fill="#EB4335" />
            <circle cx="20" cy="12" r="3" fill="#FFBA00" />
            <circle cx="12" cy="18" r="3" fill="#4C75A3" />
          </svg>
          <h1 className="text-xl font-semibold ml-2">AlumniHub</h1>
        </NavLink>
      </div>
      <div className="flex items-end">
        {username && <p className="ml-4 px-4 py-2 font-semibold text-white rounded">Hello, {username}</p>}
        {/* <a
          href="/profile"
          className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
        >
          {avatar ?
            <img src={avatar} className="font-medium text-gray-600 dark:text-gray-300" />
            :
            <span className="font-medium text-gray-600 dark:text-gray-300">
              {nameInitial}
            </span>
          }
        </a> */}
        <button className={btncss} onClick={btnAction}>
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default Header;
