import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import MentorshipAdmin from "./mentorshipAdmin";
import JobService from "../../services/job.service";

const Mentorship = () => {
  const [role, setRole] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [mentorsData, setMentorsData] = useState([]);
  const updateData = async () => {
    await authService.getMentors().then((response) => {
      setMentorsData(response.data);
    });
  };

  const [showEmail, setShowEmail] = useState(false);

  const [showInputBox, setShowInputBox] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const getRole = async() => {
     await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setRole(data.role);
      })
      .catch((error) => {
        console.error("Error retrieving role:", error);
      });
  };
  const filteredMentors = mentorsData?.filter((mentor) =>
  mentor.company?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  mentor.firstname?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  mentor.lastname?.toLowerCase().includes(searchTerm?.toLowerCase())||
  mentor.skill?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  mentor.interest?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
  mentor.profession?.toLowerCase().includes(searchTerm?.toLowerCase())
);
  useEffect( () => {
    getRole();
    updateData();
  }, []);

  //   const handleConnectRequest = () => {
  //     setShowEmail(!showEmail);
  //   };

  // const handleConnectRequest = (mentorId) => {
  //   setShowEmail((prevState) => ({
  //     ...prevState,
  //     [mentorId]: !prevState[mentorId]
  //   }));
  // };

  const handleConnectRequest = (mentorId) => {
    setShowInputBox((prevState) => ({
      ...prevState,
      [mentorId]: !prevState[mentorId],
    }));
  };

  const sendMessage = (mentorId, message) => {
    // Retrieve the mentor's email address based on the mentorId
    const mentor = mentorsData.find((mentor) => mentor._id === mentorId);
    const mentorEmail = mentor.email;

    JobService.connectMentor({mentorEmail, msg:inputValue});

    setInputValue("");

    setShowInputBox((prevState) => ({
      ...prevState,
      [mentorId]: false,
    }));
  };

  return (
    <>
      {role == "staff" ? (
        (mentorsData!=[]) && <MentorshipAdmin  updateData={updateData} />
      ) : (
        <div className="bg-[#e2e0e0] px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 justify-center">
          {/* <strong className="text-3xl font-extrabold text-left ">Mentorship</strong> */}
          <div className="flex justify-center items-center mt-3 mb-6 bg-gray-300">
            <div className="px-3 py-4 text-3xl font-extrabold tracking-wider">
              Search
            </div>
            <input
              type="text"
              className="w-2/5 px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Search by mentors' name, company, skill, interest, and profession"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="pt-8 flex justify-center bg-gray-300">
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mt-6 mb-6 w-3/5 ">
              {filteredMentors?.map((mentor) => (
                <Card
                  key={mentor._id}
                  color="gray" // Set the border color to gray-300
                  className="shadow mt-6 mb-6" // Add a shadow class for the shadow effect
                >
                  <CardHeader className="relative h-76 bg-gray-200">
                    <div className="flex items-left">
                      <div className="mr-2">
                        <img
                          src={mentor.avatar}
                          alt={mentor.firstname + " " + mentor.lastname}
                          style={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className="flex flex-col mt-3">
                        <span className="ml-2 text-3xl font-extrabold tracking-wider mb-1">
                          {mentor.firstname + " " + mentor.lastname}
                        </span>
                        <span className="ml-2 text-2xl font-bold tracking-wide mb-1">
                          {mentor.title}
                        </span>
                        <span className="ml-2 text-xl font-bold mb-1">
                          {mentor.company}
                        </span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {mentor.title && (
                      <Typography className="mb-2 text-xl text-black">
                        {" "}
                        <strong>Title: </strong> {mentor.title}
                      </Typography>
                    )}
                    {mentor.company && (
                    <Typography className="mb-2 text-xl text-black">
                      <strong>Conmpany: </strong> {mentor.company}
                    </Typography>)}
                    {mentor.department && (
                    <Typography className="mb-2 text-xl text-black">
                      <strong>Department: </strong>
                      {mentor.department}
                    </Typography>)}
                  </CardBody>

                  <CardFooter className="flex flex-col items-left">
                    {showEmail[mentor._id] && (
                      <Typography className="mb-2">{mentor.email}</Typography>
                    )}
                    {showInputBox[mentor._id] ? (
                      <div className="flex flex-col items-center mb-2">
                        <div>
                          Your message will be sent to Mentor's email address.
                        </div>
                        <textarea
                          className="w-full px-3 py-2 border border-gray-300 rounded-md resize-none mb-2 bg-gray-100 text-black"
                          rows={4}
                          placeholder="Enter your message..."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        ></textarea>
                        <div className="flex">
                          <Button
                            size="sm"
                            onClick={() => sendMessage(mentor._id)}
                            className="mr-2 text-center bg-gray-800"
                          >
                            Send
                          </Button>
                          <Button
                            size="sm"
                            onClick={() => handleConnectRequest(mentor._id)}
                            className="text-center bg-gray-500"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => handleConnectRequest(mentor._id)}
                        className="w-1/4 text-xl text-center bg-[#0f386a]"
                      >
                        {showEmail[mentor._id]
                          ? "Hide Email"
                          : "Request messaging"}
                      </Button>
                    )}
                  </CardFooter>
                  {/* </NavLink> */}
                </Card>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Mentorship;
