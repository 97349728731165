import React, { useEffect, useState } from "react";
import JobService from "../../services/job.service";


const DashboardRightTable = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    JobService.getRightTable().then((resp) => {
      const data = resp.data;
      console.log("top title", data);
      setData(data);
    });
  }, []);
  return (
    <div className='bg-[#FFFFFF] px-4 pt-3 pb-4 rounded-sm border border-gray-200 w-[20rem]'>
      <strong className="font-medium">Popular Jobs</strong>
      <div className="mt-4 flex flex-col gap-3">

        {data.map((job, index) => (

          <div key={index}>

            <div className="ml-4 flex-1">{job.name}</div>

          </div>
        ))}

      </div>

    </div>
  );
}

export default DashboardRightTable;
