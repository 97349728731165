import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiFillHome,
  AiOutlineUser,
  AiFillCalendar,
  AiTwotoneTool,
  AiFillContacts,
  AiOutlineUsergroupAdd
} from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import { CgProfile } from "react-icons/cg";

import { BiSupport, BiMoneyWithdraw, BiCast, BiChart } from "react-icons/bi";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import styles from "./sidebar.module.css";
import { IconButton } from "@material-tailwind/react";
import authService from "../../services/auth.service";

export default function SideBar(props) {

  const [open, setopen] = useState(true);

  // const [subnav, setSubnav] = useState(false);

  const [subnavCareer, setSubnavCareer] = useState(false);
  const [subnavAdmin, setSubnavAdmin] = useState(false);

  const showSidebar = (menu) => {
    if (menu === "career") {
      setSubnavCareer(!subnavCareer);
      setSubnavAdmin(false);
    } else if (menu === "admin") {
      setSubnavAdmin(!subnavAdmin);
      setSubnavCareer(false);
    }
  };

  // const showSidebar = () => setSubnav(!subnav);
  const toggleOpen = () => setopen(!open);
  const [isAdmin, setIsAdmin] = useState(false);

  const getAdmin = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setIsAdmin(data?.role == "admin")
        console.log(isAdmin)
      })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  };

  useEffect(() => {
    getAdmin();
  }, []);

  if (props.hide) return <></>;

  if (isAdmin)
    return (
      <div className={open ? styles.sidebar : styles.sidebarClosed}>
        <button className={styles.menuBtn} onClick={toggleOpen}>
          {open ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
        </button>
        <div>
          <NavLink key={13} className={styles.sideitem} to="/accounts">
            <span>
              <AiFillContacts />
            </span>
            <span className={styles.linkText}>Accounts</span>
          </NavLink>

          <NavLink key={14} className={styles.sideitem} to="/donationAdmin">
            <span>
              <BiMoneyWithdraw />
            </span>
            <span className={styles.linkText}>Donation Admin</span>
          </NavLink>
        </div>
      </div>
    )

  return (
    <div className={open ? styles.sidebar : styles.sidebarClosed}>
      <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
      </button>
      <div>
        <NavLink key={0} className={styles.sideitem} to="/home">
          <span>
            <AiFillHome />
          </span>
          <span className={styles.linkText}>Home</span>
        </NavLink>

        <NavLink key={1} className={styles.sideitem} to="/feeds">
          <span>
            <AiOutlineUsergroupAdd />
          </span>
          <span className={styles.linkText}>Feeds</span>
        </NavLink>



        <NavLink key={2} className={styles.sideitem} to="/events">
          <span>
            <AiFillCalendar />
          </span>
          <span className={styles.linkText}>Events</span>
        </NavLink>


        <NavLink
          key={4}
          className={styles.sideitem}
          onClick={() => showSidebar("career")}
        >
          <span>
            <BiSupport />
          </span>
          <span className={styles.linkText}>
            Career
          </span>
          {subnavCareer ? <RiArrowUpSFill /> : <RiArrowDownSFill />}
        </NavLink>

        {subnavCareer && (
          <div style={{ background: "black" }}>
            <NavLink key={5} className={styles.sideitem} to="/dashboard">
              <span>
                <BiChart />
              </span>
              <span className={styles.linkText}>DashBoard</span>
            </NavLink>

            <NavLink key={6} className={styles.sideitem} to="/jobs">
              <span>
                <BiCast />
              </span>
              <span className={styles.linkText}>Jobs</span>
            </NavLink>

            <NavLink key={9} className={styles.sideitem} to="/mentorship">
              <span>
                <CgProfile />
              </span>
              <span className={styles.linkText}>Mentorship</span>
            </NavLink>

          </div>
        )}


        <NavLink key={8} className={styles.sideitem} to="/findAlumni">
          <span>
            <ImLocation />
          </span>
          <span className={styles.linkText}>Find Alumni</span>
        </NavLink>

        <NavLink key={7} className={styles.sideitem} to="/profile">
          <span>
            <AiOutlineUser />
          </span>
          <span className={styles.linkText}>Profile</span>
        </NavLink>

        <NavLink key={10} className={styles.sideitem} to="/help">
          <span>
            <AiTwotoneTool />
          </span>
          <span className={styles.linkText}>Help</span>
        </NavLink>

        <div className='mt-10'>
          <NavLink key={11} to="/donation">
            <IconButton className={open ? "hidden" : "ml-1"} color="red">
              <i className="fas fa-heart" />
            </IconButton>
            <button className={open ? "ml-4 px-4 py-1 font-light text-white rounded bg-red-600 hover:bg-red-400 mr-4" : "hidden"}>Donation</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
