import React from 'react'
import FullCalendar, { formatDate } from "@fullcalendar/react";
import { Route, Link, Routes, NavLink } from 'react-router-dom';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import { useEffect, useState } from "react";
import eventService from "../../services/event.service";
import { AiOutlineDoubleLeft } from "react-icons/ai";

const EventCalendar = () => {


	const [events, setEvents] = useState([]);
	const getEvents = () => {
		eventService.getEvents().then((resp) => {
			console.log(resp.data);
			var temp = [];
			resp.data.forEach((event) =>
				temp.push({ title: event.title, date: event.eventDateTime })
			)
			console.log(temp)
			setEvents(temp)
		});
	};

	useEffect(() => {
		getEvents();
	}, []);


	return (
		<div className="w-4/5 h-screen sm:h-96 lg:h-64 mx-auto mt-8">
			<div>
				<NavLink to='/events' className="text-2xl" >
					<AiOutlineDoubleLeft />
				</NavLink>
			</div>
			<FullCalendar
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay",
				}}
				contentHeight="800px"
				initialView="dayGridMonth"
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				events={events}
				eventContent={(arg) => (
					<div className="fc-event flex center flex-col">
						<div className="fc-event-time">
							{arg.event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
						</div>
						<div className="fc-event-title whitespace-normal break-words" >
							{arg.event.title}
						</div>
					</div>
				)}
			/>

		</div>);
}

export default EventCalendar;