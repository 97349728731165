import React, { useState } from "react";
import {
  ThumbUpIcon,
  ChatAltIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/outline";
import { Input } from "@material-tailwind/react";

import FeedService from "../../services/feed.service";
import Comments from "./comments";
import FeedDropdown from "./feedDropdown";

const Posts = ({
  id,
  username,
  message,
  timestamp,
  avatar,
  imageUrl,
  isLike,
  comments,
  activateEffect,
  likeCount,
  selfAvatar,
  isOwner
}) => {
  // console.log(id, isOwner)

  const [like, setLike] = useState(isLike);
  const [comment, setComment] = useState("");
  const [toggle, setToggle] = useState(false);
  const [likeCnt, setLikeCnt] = useState(likeCount)
  const [edit, setEdit] = useState(false)
  const [editImage, setEditImage] = useState(imageUrl)
  const [editMessage, setEditMessage] = useState(message)


  const formattedTimestamp = timestamp
    ? new Date(timestamp).toLocaleString()
    : "Loading";

  const clickLike = (event) => {
    event.preventDefault();
    console.log(avatar)
    FeedService.likeFeed(id)
      .then((resp) => {
        console.log(resp.data);
        setLike(!like);
        console.log(like, likeCnt)
        if (!like) {
          setLikeCnt(likeCnt + 1)
        } else {
          setLikeCnt(likeCnt - 1)
        }
        console.log(comments);
        comments.map((comment) => console.log(comment));
      })
      .catch((error) => {
        console.error("like failed:", error);
      });
  };

  const clickComment = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const postComment = (e) => {
    e.preventDefault();
    if (comment == "") return
    FeedService.postFeedComment(id, comment)
      .then((resp) => {
        console.log(resp.data);
        activateEffect(true);
        setComment("");
      })
      .catch((error) => {
        console.error("commenting failed:", error);
      });
    console.log("post comment", comment);
  };

  const handleEdit = () => {
    console.log("click edit")
    setEdit(true);
  }

  const submitEdit = async (event) => {
    event.preventDefault();
    console.log("edit messgae", editMessage)
    console.log("edit image", editImage)

    var data = { imageUrl: editImage, message: editMessage }

    await FeedService.updateFeed(id, data).then((res) => {
      activateEffect(true);
      setEdit(false)
    })
      .catch((error) => {
        console.error("Error retrieving user profile:", error);
      });
  }

  return (
    // <div className="flex flex-col ">
    <div className="mt-5 rounded-t-2xl rounded-b-2xl border-2 shadow-sm">
      <div className="p-4 ">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <img
              src={avatar}
              alt="user"
              className="h-10 w-10 rounded-full object-cover icon"
              loading="lazy"
            />
            <div>
              <p className="font-medium">{username}</p>
              <p className="text-xs text-gray-400">{formattedTimestamp}</p>
            </div>
          </div>
          <div className="pl-10 ml-10">
            {isOwner &&
              <FeedDropdown feedId={id} refresh={() => activateEffect(true)} onEdit={handleEdit} />
            }
          </div>
        </div>

        {edit ?
          <form onSubmit={submitEdit}>
            <div>
              <label htmlFor="editor" className="sr-only">Edit</label>
              <textarea
                id="editor"
                rows="3"
                className="block  p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={editMessage}
                onChange={(e) => {
                  setEditMessage(e.target.value);
                }}
              >
              </textarea>
              {
                editImage && (
                  <div className="flex items-center flex-col" >
                    <img src={editImage} loading="lazy" alt="Post" className="p-4 max-h-screen" />
                    <button>
                      <p className="text-xs text-red-500 text-center" onClick={(e) => setEditImage(null)}>
                        Remove
                      </p>
                    </button>
                  </div>
                )
              }
            </div>
            <button type="submit" className="mt-4 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
              Save
            </button>
          </form>
          : <>
            <p className="pt-4">{message}</p>
            {
              imageUrl && (
                <div className="flex items-center justify-center">
                  <img src={imageUrl} loading="lazy" alt="Post" className="p-4 max-h-screen" />
                </div>
              )
            }
          </>}

      </div >
      <div className="flex justify-evenly items-center text-gray-400 border-t border-b">
        <button
          className=" flex justify-center items-center p-3 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 flex-1 "
          onClick={clickLike}
        >
          <ThumbUpIcon className={like ? "h-5 text-blue-500" : "h-5"} />
          <p className={like ? "text-base  text-blue-500" : "text-base"}>
            Like {likeCnt === 0 ? "" : likeCnt}
          </p>
        </button>

        <button
          className="flex justify-center items-center p-3 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 flex-1"
          onClick={clickComment}
        >
          <ChatAltIcon className="h-5" />
          <p className="text-base">Comment</p>
        </button>
      </div>

      {
        toggle && (
          <form onSubmit={postComment} className="flex flex-col">
            <div className="flex items-center space-x-4 p-2 ">
              <img
                src={selfAvatar}
                alt="user"
                className="h-10 w-10 rounded-full object-cover icon"
                loading="lazy"
              />

              <div className="w-72 flex flex-1">
                <Input
                  type="tel"
                  placeholder="What's your comment?"
                  className="rounded-full !border-t-blue-gray-200 focus:!border-t-blue-500 mt-0"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-0",
                  }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  icon={
                    <svg className="w-5 h-5 rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20" onClick={postComment}>
                      <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                    </svg>}
                />
              </div>
            </div>
            <div className="text-center">
              {comments.map((c) => (
                <Comments
                  key={c._id}
                  comment={c.comment}
                  user={c.user}
                  createdAt={c.createdAt}
                />
              ))}
            </div>
          </form>
        )
      }
    </div >
  );
};


export default Posts;
