import React from "react";
import { Route, Navigate } from "react-router-dom";
import commonHelper from "../helper/commonHelper";
import SideBar from "../navigation/sideBar";

const RouteGuard = ({ component: Component, ...rest }) => {
  function hasJWT() {
    let flag = false;
    commonHelper.getCookie('token') ? (flag = true) : (flag = false);

    return flag;
  }

  return hasJWT() ? (
    <>
      <SideBar />
      <main className="flex-1 overflow-y-auto">
        <Component {...rest} />
      </main>
    </>

  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );
};

export default RouteGuard;
