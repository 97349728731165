import axios from "axios";

const isProduction = process.env.NODE_ENV === 'production';


const API_URL =  (isProduction?"":"http://localhost:3000") + "/api/job/";

const JobService = {

	getJob() {
		return axios.get(API_URL, {
			withCredentials: true,
		});
	},

	searchJob(keyword) {
		return axios.get(API_URL + `search?keyword=${keyword}`, {
			withCredentials: true,
		});
	},

	getBarChart() {
		return axios.get(API_URL + 'dashboard/barchart', {
			withCredentials: true,
		});
	},
	getPieChart() {
		return axios.get(API_URL + 'dashboard/pieChart', {
			withCredentials: true,
		});
	},
	getRightTable() {
		return axios.get(API_URL + 'dashboard/rightTable', {
			withCredentials: true,
		});
	},
	getStatGrid() {
		return axios.get(API_URL + 'dashboard/statGrid', {
			withCredentials: true,
		});
	},
	connectMentor(data) {
		return axios.post(API_URL + 'connectMentor', data, {
			withCredentials: true,
		});
	}



};

export default JobService;
