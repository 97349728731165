import React, { useEffect, useState } from "react";
import JobService from "../../services/job.service";
import { IoBagHandle } from 'react-icons/io5';

const DashboardStatsGrid = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        JobService.getStatGrid().then((resp) => {
            const data = resp.data;
            console.log("stat grid", data);
            setData(data);
        });
    }, []);

    return (
        <div className='flex gap-4 w-full'>
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-[#3b00df]">
                    <IoBagHandle className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light"> Total Jobs</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold"> {data[0]?.total_cnt}</strong>
                        <span className="text-sm text-green-500 pl-2"> +{data[0]?.today_cnt} </span>
                    </div>
                </div>

            </BoxWrapper>
            {/* <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
                <IoBagHandle className="text-2xl text-white" />
            </div>
            <div className="pl-4">
                <span className="text-sm text-gray-500 font-light"> Admin Jobs</span>
                <div className="flex items-center">
                    <strong className="text-xl text-gray-700 font-semibold"> 1800</strong>
                    <span className="text-sm text-green-500 pl-2"> +343 </span>
                </div>
            </div>
           
        </BoxWrapper> */}
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
                    <IoBagHandle className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light"> IT Jobs</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold"> {data[0]?.total_cnt}</strong>
                        <span className="text-sm text-green-500 pl-2"> +{data[0]?.today_cnt} </span>
                    </div>
                </div>

            </BoxWrapper>
            {/* <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-400">
                <IoBagHandle className="text-2xl text-white" />
            </div>
            <div className="pl-4">
                <span className="text-sm text-gray-500 font-light"> Account Jobs</span>
                <div className="flex items-center">
                    <strong className="text-xl text-gray-700 font-semibold"> 1200</strong>
                    <span className="text-sm text-green-500 pl-2"> +-28 </span>
                </div>
            </div>
           
        </BoxWrapper> */}

        </div>
    );
}

export default DashboardStatsGrid;

function BoxWrapper({ children }) {
    return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
        {children}
    </div>
}