import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import eventService from "../../services/event.service";
import commonHelper from "../helper/commonHelper";
import authService from "../../services/auth.service";

const EventsItem = ({ isOwner }) => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [updatedEvent, setUpdatedEvent] = useState({});
  const [role, setRole] = useState("");

  const updateEvent = () => {
    eventService
      .getEvents(id)
      .then((resp) => {
        var data = resp.data[0];
        setEvent({ ...data, eventDateTime: utcToLocalStr(data.eventDateTime) });
        console.log("event", event);
      })
      .catch((e) => {
        return <Navigate to="/events" />;
      });
  };

  const getRole = async () => {
    await authService
      .getUserProfile()
      .then((res) => {
        const data = res.data;
        setRole(data.role);
      })
      .catch((error) => {
        console.error("Error retrieving role:", error);
      });
  };

  useEffect(() => {
    updateEvent();
    getRole();
  }, []);

  // Destructure event properties
  const { eventDateTime, location, contact, title, description } = event;

  const handleEditButtonClick = () => {
    setEditMode(true);
    setUpdatedEvent(event);
  };

  const handleSaveButtonClick = (_id) => {
    setEvent(updatedEvent);
    setEditMode(false);
    console.log(updatedEvent);
    // call api update change
    eventService.updateEvent(_id, updatedEvent);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEvent({ ...updatedEvent, [name]: value });
  };

  const utcToLocalStr = (utcDateString) => {
    const date = new Date(utcDateString);
    const localDateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);
    return localDateString;
  };

  return (
    <div className="max-w-[800px] w-full mx-auto flex flex-col justify-center">
      <div className="p-5  bg-[#FEFEFE] mt-5 rounded-t-2xl shadow-sm">
        <div className="flex items-center ">
          <div className="w-full">
            <div className="text-[#ef8437] font-bold text-4xl ">
              <label className="block text-gray-700 text-4xl font-bold w-full">
                Title:
              </label>
              {editMode ? (
                <input
                  type="text"
                  name="title"
                  value={updatedEvent.title || title}
                  onChange={handleInputChange}
                  className=" text-[#ef8437] font-bold text-4xl border border-gray-500 rounded-lg focus:ring focus:ring-blue-200 focus:border-blue-500 w-full"
                />
              ) : (
                <span>{updatedEvent.title || title}</span>
              )}
            </div>
            <div className="text-2xl md:pt-6">Description</div>
            <div className="font-medium md:py-2">
              {editMode ? (
                <textarea
                  name="description"
                  value={updatedEvent.description || description}
                  onChange={handleInputChange}
                  className="font-meduim text-xl border border-gray-500 rounded-lg focus:ring focus:ring-blue-200 focus:border-blue-500 w-full"
                />
              ) : (
                <span>{description}</span>
              )}
            </div>
            <div className="text-2xl md:pt-4">Date and time</div>
            {editMode ? (
              <input
                type="datetime-local"
                name="eventDateTime"
                value={updatedEvent.eventDateTime || eventDateTime}
                onChange={handleInputChange}
                className="w-full text-gray-700 border border-gray-500 rounded-lg focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
            ) : (
              <div className="font-medium md:py-2">
                {new Date(eventDateTime).toLocaleString()}
              </div>
            )}
      
            <div className="text-2xl md:pt-4">Location</div>
            {editMode ? (
              <input
                type="text"
                name="location"
                value={updatedEvent.location || location}
                onChange={handleInputChange}
                className="w-full text-gray-700 border border-gray-500 rounded-lg focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
            ) : (
              <div className="font-medium md:py-2">{location}</div>
            )}

            <div className="text-2xl md:pt-4">Contact</div>
            {editMode ? (
              <input
                type="text"
                name="contact"
                value={updatedEvent.contact || contact}
                onChange={handleInputChange}
                className="w-full text-gray-700 border border-gray-500 rounded-lg focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
            ) : (
              <div className="font-medium md:py-2">{contact}</div>
            )}

            <div className="text-2xl md:pt-4">Event Owner</div>
            <div className="font-medium md:py-2">
              {event.EventOwner?.firstname + " " + event.EventOwner?.lastname}
            </div>

            <div className="text-2xl md:pt-4">Event Attendees</div>
            <div className="font-medium md:py-2">
              <ul style={{ listStyleType: "square" }}>
                {event.EventAttendee?.map((e) => {
                  return <li key={e._id}>{e.firstname + " " + e.lastname}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {(role == "staff" ||
        event?.EventOwner?._id == commonHelper.getCookie("userId")) && (
        <div className="flex justify-end mt-4">
          {!editMode ? (
            <button
              className="px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              onClick={handleEditButtonClick}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                className="px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
                onClick={() => handleSaveButtonClick(event._id)}
              >
                Save
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default EventsItem;
