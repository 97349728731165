import Analytics from "../components/intro/Analytics";
import Footer from "../components/intro/Footer";
import Hero from "../components/intro/Hero";
import Newsletter from "../components/intro/Newsletter";



const IntroPage = () => {
	return (
		<div className="flex-1">
			<Hero />
			<Analytics />
			<Newsletter />
			<Footer />
		</div>
	);
}

export default IntroPage;