import React, { useEffect, useState, Component } from 'react';
import { Navigate, Route, useNavigate } from "react-router-dom";
import background from '../../images/background.jpg'
import AuthService from "../../services/auth.service";
import FormHeader from './formHeader';

class forgetPassword extends Component {

    state = {
        email: "",
        password: "",
        error: "",
        isPasswordChanged: false
    }
    constructor(props) {
        super(props);
    }

    isValidEmail(temail) {
        return /\S+@\S+\.\S+/.test(temail);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ error: "Email cannot be empty" })
            return;
        } else if (!this.isValidEmail(this.state.email)) {
            this.setState({ error: "please enter valid email" })
            return;
        }

        AuthService.forgetPassword(this.state.email)
            .then(response => {
                if (response.status == 200) {
                    this.setState({ email: "", isPasswordChanged: true })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ error: "User not Found" })
            })
    }
    handleChange = (event) => {
        if (!this.isValidEmail(event.target.value)) {
            this.setState({ error: 'Email is invalid' })
        } else {
            this.setState({ error: null })
        }
        this.setState({ email: event.target.value, isPasswordChanged: false })
    }



    render() {
        const { email, isPasswordChanged, error } = this.state
        return (
            <div className='md:w-8/12 max-w-xl relative z-10 m-auto px-6 lg:mt-16'>
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                    <FormHeader
                        heading="Reset Password"
                        paragraph="Don't remember your email address?"
                        linkName="Contact us"
                        linkUrl="mailto:info@alumnihub.com"
                    />
                    <br>
                    </br>

                    {error && (
                        <div
                            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-3 mb-3 relative"
                            role="alert"
                        >
                            <p className="font-bold">Error Occured!</p>
                            <p>{error}.</p>
                        </div>
                    )}

                    {isPasswordChanged &&
                        <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                            <div className="flex">
                                <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                <div>
                                    <p className="font-bold">Password Changed</p>
                                    <p className="text-sm">Tempory password has been sent to your email address.</p>
                                    Go back to <a href="/login" className="text-blue-500 hover:text-blue-700">Login </a>
                                </div>
                            </div>
                        </div>
                    }
                    <form onSubmit={this.handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                                id="email"
                                type="text"
                                value={email}
                                onChange={this.handleChange}
                                placeholder="Email"
                                required />
                            {/* {this.state.error && <h2 style={{ color: 'red' }}>{this.state.error}</h2>} */}
                        </div>
                        <div className="flex items-center justify-between">
                            <button type='submit' className="bg-blue-600 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded">
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>);
    }
}

export default forgetPassword;